import React, { useEffect, useRef, useState } from "react";
import Modal from "bootstrap/js/dist/modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDropzone } from "react-dropzone";
import BranchSelector from "./BranchSelector";
import PrintTypeSelector from "./PrintTypeSelector";
import { useUsers } from "./UserContext";
import UserSelector from "./UserSelector";
import TagInput from "./TagInput";

// Componente per caricamento file con gestione e approvazione
const FileUploadWithApproval = ({
  label,
  fieldName,
  fileUrl,
  onFileUpload,
  approvalStatus,
  onApprove,
  onRevoke,
  notes,
  onNotesChange,
  readOnly,
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      console.log("Accepted Files:", acceptedFiles); // Controlla il file
      console.log("Field Name:", fieldName); // Controlla che il nome sia corretto
      onFileUpload(acceptedFiles[0]);
    },
    multiple: false,
  });

  return (
    <div className="mb-3">
      <label className="form-label">{label}</label>
      {fileUrl ? (
        <div className="d-flex align-items-center">
          <a
            href={fileUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-link me-2"
          >
            Visualizza Allegato
          </a>
          {!readOnly && ( // Nascondi il pulsante "Salva Modifiche" in modalità sola lettura
            <button
              type="button"
              className="btn btn-danger btn-sm"
              onClick={() => onFileUpload(null)}
            >
              Rimuovi File
            </button>
          )}
        </div>
      ) : (
        <div
          {...getRootProps()}
          className={`dropzone border p-3 text-center ${
            isDragActive ? "bg-light" : ""
          }`}
          style={{ cursor: "pointer" }}
        >
          <input {...getInputProps()} name={fieldName} />
          {isDragActive ? (
            <p>Trascina il file qui...</p>
          ) : (
            <p>Clicca o trascina il file qui per caricare</p>
          )}
        </div>
      )}
      {notes !== undefined && (
        <div className="mt-2">
          <label className="form-label">Note di Revisione</label>
          <textarea
            className="form-control"
            rows="2"
            value={notes}
            onChange={(e) => {
              if (!readOnly) {
                onNotesChange(e);
              }
            }}
            readOnly={readOnly}
            disabled={readOnly}
          ></textarea>
        </div>
      )}
      <div className="mt-2">
        {approvalStatus ? (
          !readOnly && (
            <button
              type="button"
              className="btn btn-warning btn-sm"
              onClick={() => {
                console.log(`Revoca approvazione per ${fieldName}`);
                onRevoke();
              }}
            >
              Revoca Approvazione
            </button>
          )
        ) : (
          <button
            type="button"
            className="btn btn-success btn-sm"
            onClick={() => {
              console.log(`Approva file per ${fieldName}`);
              onApprove();
            }}
          >
            Approva
          </button>
        )}
      </div>
    </div>
  );
};

function TaskModalPubblicitari({
  taskToEdit,
  isOpen,
  onClose,
  onTaskAdded,
  onTaskUpdated,
  onTaskDeleted,
  readOnly, // Nuova prop
}) {
  const [fileData, setFileData] = useState({
    latestFile: null,
    latestNotes: "",
    latestApproval: false,
    printFile: null,
    printNotes: "",
    printApproval: false,
  });

  const modalRef = useRef(null);
  const bsModal = useRef(null);

  const [branch, setBranch] = useState(taskToEdit?.branch || "Mostra Tutto");

  const { users } = useUsers(); // Accedi agli utenti tramite il context
  const [selectedUsers, setSelectedUsers] = useState([]); // Stato per gli utenti selezionati
  const [selectedTags, setSelectedTags] = useState(taskToEdit?.tags || []);



  useEffect(() => {
    if (modalRef.current) {
      bsModal.current = new Modal(modalRef.current, {
        backdrop: "static",
        keyboard: false,
      });
    }
  }, []);

  useEffect(() => {
    if (isOpen && bsModal.current) {
      bsModal.current.show();
    } else if (bsModal.current) {
      bsModal.current.hide();
    }
  }, [isOpen]);

  useEffect(() => {
    console.log("Valore iniziale di taskToEdit:", taskToEdit);
    if (taskToEdit) {
      formik.setValues({
        title: taskToEdit.title || "",
        description: taskToEdit.description || "",
        latestApproval: Boolean(taskToEdit.latest_approval), // Converte in booleano
        printApproval: Boolean(taskToEdit.print_approval), // Converte in booleano
        due_date: taskToEdit.due_date
          ? taskToEdit.due_date.split("T")[0] // Formatta la data per <input type="date">
          : "",
        print: Boolean(taskToEdit.print),
        print_status:
          taskToEdit.print_status || (taskToEdit.print ? "in-lavorazione" : ""),
        status: taskToEdit.status || "todo",
        priority: taskToEdit.priority || 3,
        print_type: taskToEdit?.print_type || "Seleziona Tipo",
        supplier: taskToEdit.supplier || "",
        customSupplier:
          taskToEdit.supplier === "altro"
            ? taskToEdit.custom_supplier || ""
            : "", // Aggiungi questo
      });
      setBranch(taskToEdit.branch || "Mostra Tutto"); // Imposta il branch esistente
      setSelectedTags(taskToEdit.tags || []);
      setFileData({
        latestFile: taskToEdit.latest_file || null,
        latestNotes: taskToEdit.latest_notes || "",
        latestApproval: Boolean(taskToEdit.latest_approval), // Forza booleano
        printFile: taskToEdit.print_file || null,
        printNotes: taskToEdit.print_notes || "",
        printApproval: Boolean(taskToEdit.print_approval), // Forza booleano
      });
    } else {
      formik.resetForm();
      setBranch("Mostra Tutto"); // Default al branch "Mostra Tutto"
      setFileData({
        latestFile: null,
        latestNotes: "",
        latestApproval: false,
        printFile: null,
        printNotes: "",
        printApproval: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskToEdit]);

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      due_date: "",
      print: false,
      print_status: "",
      status: "todo",
      priority: 3,
      supplier: "",
      customSupplier: "", // Aggiunto qui
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Il titolo è richiesto"),
    }),
    onSubmit: async (values) => {
      console.log('Utenti selezionati:', selectedUsers); // Aggiungi questo log
      console.log('File data state during submit:', fileData); // Controlla lo stato dei file
      const formData = new FormData();

      // Aggiungi i campi di testo
      formData.append("title", values.title);
      formData.append("description", values.description || "");
      formData.append("emailRecipients", JSON.stringify(selectedUsers));
      formData.append("due_date", values.due_date || "");
      formData.append("print", values.print ? 1 : 0);
      formData.append("print_status", values.print ? values.print_status : "");
      formData.append("status", values.status);
      formData.append("priority", values.priority);
      formData.append("print_type", "Mostra Tutto"); // Assicurati che sia un valore valido
      formData.append("branch", branch); // Questo è lo stato gestito da React
      formData.append("tags", JSON.stringify(selectedTags));

      // Gestione del fornitore e fornitore personalizzato
      if (values.supplier === "altro") {
        formData.append("supplier", "altro");
        formData.append("customSupplier", values.customSupplier || ""); // Valore personalizzato
      } else {
        formData.append("supplier", values.supplier || "");
        formData.append("customSupplier", ""); // Pulisci se non è altro
      }

      // Aggiungi i file reali
      if (fileData.latestFile) {
        console.log('Aggiunta latest_file:', fileData.latestFile);
        formData.append("latest_file", fileData.latestFile); // File reale
      }
      if (fileData.printFile) {
        console.log('Aggiunta print_file:', fileData.printFile);
        formData.append("print_file", fileData.printFile); // File reale
      }

      // Aggiungi approvazioni e note
      formData.append("latest_approval", fileData.latestApproval ? 1 : 0);
      formData.append("latest_notes", fileData.latestNotes || "");
      formData.append("print_approval", fileData.printApproval ? 1 : 0);
      formData.append("print_notes", fileData.printNotes || "");

      console.log(
        "Contenuto FormData inviato:",
        Array.from(formData.entries())
      );

      try {
        let response;

        if (taskToEdit) {
          // Aggiorna una task esistente
          response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/tasks/${taskToEdit.id}`,
            {
              method: "PUT",
              body: formData,
            }
          );
        } else {
          // Crea una nuova task
          console.log('Eseguito POST per nuova task');
          response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/tasks`,
            {
              method: "POST",
              body: formData,
            }
          );
        }

        if (!response.ok) {
          throw new Error(
            taskToEdit
              ? "Errore durante l'aggiornamento della task"
              : "Errore durante la creazione della task"
          );
        }

        const result = await response.json();
        console.log("Risultato dal server:", result);

        if (taskToEdit) {
          onTaskUpdated(result);
        } else {
          if (typeof onTaskAdded === "function") {
            onTaskAdded(result);
          } else {
            console.warn("onTaskAdded non è una funzione valida");
          }
        }
        
        

        onClose(); // Chiudi il modal
      } catch (error) {
        console.error("Errore nella richiesta:", error);
      }
    },
  });





  const handleDelete = async () => {
    if (!taskToEdit) return;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/tasks/${taskToEdit.id}`,
        {
          method: "DELETE",
        }
      );
      if (!response.ok) throw new Error("Errore durante l'eliminazione della task");
  
      if (typeof onTaskDeleted === "function") {
        onTaskDeleted(taskToEdit.id); // Chiama solo se è una funzione valida
      } else {
        console.warn("onTaskDeleted non è una funzione valida");
      }
      onClose();
    } catch (error) {
      console.error("Errore durante l'eliminazione della task:", error);
    }
  };
  
  const handleFileUpload = async (type, file) => {
    console.log('Handle File Upload chiamato per:', type, file);

  const formData = new FormData();
  formData.append(type === "latest" ? "latest_file" : "print_file", file);

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/upload/`, // Aggiungi la slash finale qui
      {
        method: "POST",
        body: formData,
      }
    );
    
    console.log('Risposta upload file:', response);
    if (!response.ok) throw new Error("Errore durante il caricamento del file");

    // Estrarre gli URL restituiti dal server
    const { latest_file, print_file } = await response.json();
    console.log('File URL ricevuto dal server:', { latest_file, print_file });

    // Aggiorna lo stato con il file reale e l'URL
    setFileData((prev) => ({
      ...prev,
      [`${type}File`]: file,
      [`${type}FileUrl`]: type === "latest" ? latest_file : print_file,
    }));

    console.log("File caricato con successo:", {
      file,
      url: type === "latest" ? latest_file : print_file,
    });
  } catch (error) {
    console.error("Errore durante il caricamento del file:", error);
  }
};


  const handleFileRemove = async (type) => {
    console.log(`Handle File Remove chiamato per: ${type}`);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/tasks/${taskToEdit.id}/remove-file`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ fileType: type }),
        }
      );console.log('Risposta rimozione file:', response);
  
      if (!response.ok) {
        throw new Error("Errore durante la rimozione del file");
      }
  
      // Aggiorna lo stato locale
      setFileData((prev) => ({
        ...prev,
        [`${type}File`]: null,
        [`${type}FileUrl`]: null,
      }));
  
      console.log(`File ${type} rimosso con successo`);
    } catch (error) {
      console.error('Errore durante handleFileRemove:', error);
    }
  };
  
  


  

  const handleApprovalToggle = (type, approved) => {
    setFileData((prev) => ({
      ...prev,
      [`${type}Approval`]: approved,
    }));
  };

  return (
    <div
      className="modal fade"
      id="addTaskModal"
      tabIndex="-1"
      aria-labelledby="addTaskModalLabel"
      aria-hidden={!isOpen}
      ref={modalRef}
    >
      <div className="modal-dialog modal-xl">
        <form onSubmit={formik.handleSubmit}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addTaskModalLabel">
                {taskToEdit ? "Modifica Attività" : "Aggiungi Attività"}
              </h5>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={onClose}
              ></button>
            </div>
            <div className="modal-body">
              {readOnly && (
                <div className="alert alert-warning" role="alert">
                  Questa task è archiviata. Non è possibile modificarla.
                </div>
              )}
              <div className="row">
                {/* Colonna principale */}
                <div className={`col-lg-${taskToEdit ? "6" : "12"}`}>
                  <div className="mb-3">
                    <BranchSelector
                      value={branch}
                      onChange={setBranch} // Aggiorna lo stato con il valore selezionato
                      disabled={readOnly} // Disabilita se in modalità sola lettura
                    />
                  </div>
                  <div className="mb-3">
                  <TagInput
                      selectedTags={selectedTags}
                      onTagsChange={(newTags) => setSelectedTags(newTags)}
                      readOnly={readOnly}
                    />
                </div>
                  {!taskToEdit && (
                    <div className="mb-3">
                      <UserSelector
                        users={users} // Elenco degli utenti dal context
                        selectedUsers={selectedUsers} // Stato degli utenti selezionati
                        onChange={setSelectedUsers} // Callback per aggiornare gli utenti selezionati
                      />
                    </div>
                  )}
                    <div className="mb-3">
                    <label htmlFor="title" className="form-label">
                      Titolo
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        formik.touched.title && formik.errors.title
                          ? "is-invalid"
                          : ""
                      }`}
                      id="title"
                      name="title"
                      value={formik.values.title}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      required
                      disabled={readOnly} // Aggiunto
                    />
                    {formik.touched.title && formik.errors.title ? (
                      <div className="invalid-feedback">
                        {formik.errors.title}
                      </div>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="description" className="form-label">
                      Descrizione
                    </label>
                    <textarea
                      className="form-control"
                      id="description"
                      name="description"
                      rows="3"
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      readOnly={readOnly} // Campo descrizione non modificabile in modalità readOnly
                      disabled={readOnly} // Aggiungi questa riga se vuoi anche disabilitarlo completamente
                    ></textarea>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="due_date" className="form-label">
                      Data di Scadenza
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id="due_date"
                      name="due_date"
                      value={formik.values.due_date}
                      onChange={formik.handleChange}
                      disabled={readOnly} // Campo titolo non modificabile
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="priority" className="form-label">
                      Priorità
                    </label>
                    <select
                      id="priority"
                      name="priority"
                      className="form-select"
                      value={formik.values.priority}
                      onChange={formik.handleChange}
                      disabled={readOnly} // Campo titolo non modificabile
                    >
                      <option value={1}>1 - Molto Alta</option>
                      <option value={2}>2 - Alta</option>
                      <option value={3}>3 - Media</option>
                      <option value={4}>4 - Bassa</option>
                      <option value={5}>5 - Molto Bassa</option>
                    </select>
                  </div>
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="print"
                      name="print"
                      checked={formik.values.print}
                      onChange={formik.handleChange}
                      disabled={readOnly} // Campo titolo non modificabile
                    />
                    <label className="form-check-label" htmlFor="print">
                      Necessita di Stampa
                    </label>
                  </div>
                  {formik.values.print && (
                    <>
                      <div className="mb-3">
                        <PrintTypeSelector
                          value={formik.values.print_type}
                          disabled={readOnly} // Disabilita se in modalità sola lettura
                          onChange={(value) =>
                            formik.setFieldValue("print_type", value)
                          }
                          isModal={true}
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="supplier" className="form-label">
                          Fornitore
                        </label>
                        <select
                          id="supplier"
                          name="supplier"
                          className="form-select"
                          value={formik.values.supplier}
                          disabled={readOnly} // Campo titolo non modificabile
                          onChange={(e) => {
                            formik.handleChange(e);
                            if (e.target.value !== "altro") {
                              formik.setFieldValue("customSupplier", ""); // Reset customSupplier se non è altro
                            }
                          }}
                        >
                          <option value="">Seleziona un Fornitore</option>
                          <option value="flyer-alarm">Flyer Alarm</option>
                          <option value="grafica-nappa">Grafica Nappa</option>
                          <option value="del-duca-print">Del Duca Print</option>
                          <option value="altro">Altro</option>
                        </select>
                      </div>
                      {formik.values.supplier === "altro" && (
                        <div className="mb-3">
                          {/*<label htmlFor="customSupplier" className="form-label">Fornitore Personalizzato</label>*/}
                          <input
                            type="text"
                            className="form-control"
                            id="customSupplier"
                            name="customSupplier"
                            value={formik.values.customSupplier}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            disabled={readOnly} // Campo titolo non modificabile
                          />
                          {formik.touched.customSupplier &&
                          formik.errors.customSupplier ? (
                            <div className="invalid-feedback">
                              {formik.errors.customSupplier}
                            </div>
                          ) : null}
                        </div>
                      )}
                    </>
                  )}
                </div>
                {/* Colonna aggiuntiva per contenuto extra */}
                {taskToEdit && (
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <FileUploadWithApproval
                        label="Carica Ultima Versione del File"
                        fieldName="latest_file"
                        fileUrl={fileData.latestFile || null}
                        
                        disabled={readOnly} // Aggiungi questa riga se vuoi anche disabilitarlo completamente
                        onFileUpload={(file) => file ? handleFileUpload("latest", file) : handleFileRemove("latest")}
                        approvalStatus={fileData.latestApproval}
                        onApprove={() => handleApprovalToggle("latest", true)}
                        onRevoke={() => handleApprovalToggle("latest", false)}
                        notes={fileData.latestNotes}
                        onNotesChange={(e) =>
                          setFileData((prev) => ({
                            ...prev,
                            latestNotes: e.target.value,
                          }))
                        }
                        readOnly={readOnly} // Passa correttamente la prop readOnly
                      />
                    </div>
                    {formik.values.print && (
                      <div className="mb-3">
                        <FileUploadWithApproval
                          label="Carica Preventivo"
                          fieldName="print_file"
                          fileUrl={fileData.printFile || null}
                          onFileUpload={(file) =>
                            file ? handleFileUpload("print", file) : handleFileRemove("print")
                          }
                          approvalStatus={fileData.printApproval}
                          onApprove={() => handleApprovalToggle("print", true)}
                          onRevoke={() => handleApprovalToggle("print", false)}
                          notes={fileData.printNotes}
                          onNotesChange={(e) =>
                            setFileData((prev) => ({
                              ...prev,
                              printNotes: e.target.value,
                            }))
                          }
                          readOnly={readOnly} // Passa correttamente la prop readOnly
                        />
                      </div>
                    )}
                    {taskToEdit && formik.values.print && (
                      <div className="mb-3">
                        <label htmlFor="print_status" className="form-label">
                          Stato di Stampa
                        </label>
                        <select
                          id="print_status"
                          name="print_status"
                          className="form-select"
                          value={formik.values.print_status}
                          onChange={formik.handleChange}
                          disabled={readOnly} // Campo non modificabile in sola lettura
                        >
                          <option value="in-lavorazione">In Lavorazione</option>
                          <option value="ordered">Ordinato</option>
                          <option value="in-delivery">In Consegna</option>
                          <option value="received">Ricevuto</option>
                        </select>
                      </div>
                    )}
                    <div className="mb-3">
                      <label htmlFor="status" className="form-label">
                        Stato
                      </label>
                      <select
                        id="status"
                        name="status"
                        className="form-select"
                        value={formik.values.status}
                        onChange={formik.handleChange}
                        disabled={readOnly} // Campo titolo non modificabile
                      >
                        <option value="todo">Da Fare</option>
                        <option value="in-progress">In Corso</option>
                        <option value="in-print">In Stampa</option>
                        <option value="done">Completato</option>
                      </select>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="modal-footer">
              {taskToEdit &&
                !readOnly && ( // Mostra solo se non è in modalità sola lettura
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={handleDelete}
                  >
                    Elimina
                  </button>
                )}
              <button
                type="button"
                className="btn btn-secondary"
                onClick={onClose}
              >
                Chiudi
              </button>
              {!readOnly && ( // Nascondi il pulsante "Salva Modifiche" in modalità sola lettura
                <button type="submit" className="btn btn-primary">
                  {taskToEdit ? "Salva Modifiche" : "Aggiungi"}
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default TaskModalPubblicitari;
