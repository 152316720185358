import React, { useState, useEffect, useRef } from "react";
import Modal from "bootstrap/js/dist/modal";
import BranchSelector from "./BranchSelector";
import { useUsers } from "./UserContext";
import UserSelector from "./UserSelector";
import TagInput from "./TagInput";

function TaskModalWeb({
  taskToEdit,
  isOpen,
  onClose,
  onTaskAdded,
  onTaskUpdated,
  onTaskDeleted,
  readOnly,
}) {
  const [taskTitle, setTaskTitle] = useState("");
  const [taskDescription, setTaskDescription] = useState("");
  const [taskDueDate, setTaskDueDate] = useState("");
  const [taskStatus, setTaskStatus] = useState("todo");
  const [taskPriority, setTaskPriority] = useState(3); // Priorità della task
  const [selectedTags, setSelectedTags] = useState(taskToEdit?.tags || []);

  const modalRef = useRef(null);
  const bsModal = useRef(null);

  const [branch, setBranch] = useState(taskToEdit?.branch || "Mostra Tutto");
  const { users } = useUsers(); // Accedi agli utenti tramite il context
  const [selectedUsers, setSelectedUsers] = useState([]); // Stato per gli utenti selezionati

  useEffect(() => {
    if (modalRef.current) {
      bsModal.current = new Modal(modalRef.current, {
        backdrop: "static",
        keyboard: false,
      });
    }
  }, []);

  useEffect(() => {
    if (isOpen && bsModal.current) {
      bsModal.current.show();
    } else if (bsModal.current) {
      bsModal.current.hide();
    }
  }, [isOpen]);

  useEffect(() => {
    if (taskToEdit) {
      setTaskTitle(taskToEdit.title || "");
      setTaskDescription(taskToEdit.description || "");
      setTaskDueDate(taskToEdit.due_date || "");
      setTaskStatus(taskToEdit.status || "todo");
      setTaskPriority(taskToEdit.priority || 3);
      setBranch(taskToEdit.branch || "Mostra Tutto"); // Imposta il branch esistente
      setSelectedTags(taskToEdit.tags || []);
    } else {
      setTaskTitle("");
      setTaskDescription("");
      setTaskDueDate("");
      setTaskStatus("todo");
      setTaskPriority(3);
      setBranch("Mostra Tutto"); // Default al branch "Roydermal"
    }
  }, [taskToEdit]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formattedDueDate = taskDueDate
      ? new Date(taskDueDate).toISOString().split("T")[0]
      : null;

    const taskData = {
      title: taskTitle,
      description: taskDescription,
      due_date: formattedDueDate,
      status: taskStatus,
      category: "Web",
      priority: taskPriority,
      tags: JSON.stringify(selectedTags), // Stringifica i tag
    };

    try {
      if (taskToEdit) {
        // Update task
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/tasks/${taskToEdit.id}`,
          {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(taskData),
          }
        );
        if (!response.ok)
          throw new Error("Errore durante l'aggiornamento della task");
        const updatedTask = await response.json();
        onTaskUpdated(updatedTask);
      } else {
        // Add task
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/tasks`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(taskData),
          }
        );
        if (!response.ok)
          throw new Error("Errore durante l'aggiunta della task");
        const newTask = await response.json();
        onTaskAdded(newTask);
      }
      onClose();
    } catch (error) {
      console.error("Errore:", error);
    }
  };

  const handleDelete = async () => {
    if (!taskToEdit) return;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/tasks/${taskToEdit.id}`,
        {
          method: "DELETE",
        }
      );
      if (!response.ok)
        throw new Error("Errore durante l'eliminazione della task");
      onTaskDeleted(taskToEdit.id);
      onClose();
    } catch (error) {
      console.error("Errore durante l'eliminazione della task:", error);
    }
  };

  return (
    <div
      className="modal fade"
      id="addTaskModal"
      tabIndex="-1"
      aria-labelledby="addTaskModalLabel"
      aria-hidden={!isOpen}
      ref={modalRef}
    >
      <div className="modal-dialog">
        <form onSubmit={handleSubmit}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addTaskModalLabel">
                {taskToEdit ? "Modifica Attività" : "Aggiungi Attività"}
              </h5>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={onClose}
              ></button>
            </div>
            <div className="modal-body">
              {readOnly && (
                <div className="alert alert-warning" role="alert">
                  Questa task è archiviata. Non è possibile modificarla.
                </div>
              )}
              <div className="mb-3">
                <BranchSelector
                  value={branch}
                  onChange={setBranch} // Aggiorna lo stato con il valore selezionato
                />
              </div>
              <div className="mb-3">
                  <TagInput
                      selectedTags={selectedTags}
                      onTagsChange={(newTags) => setSelectedTags(newTags)}
                      readOnly={readOnly}
                      
                    />
                </div>
              {!taskToEdit && (
                    <div className="mb-3">
                      <UserSelector
                        users={users} // Elenco degli utenti dal context
                        selectedUsers={selectedUsers} // Stato degli utenti selezionati
                        onChange={setSelectedUsers} // Callback per aggiornare gli utenti selezionati
                      />
                    </div>
                  )}
              <div className="mb-3">
                <label htmlFor="taskTitle" className="form-label">
                  Titolo
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="taskTitle"
                  value={taskTitle}
                  onChange={(e) => setTaskTitle(e.target.value)}
                  required
                  disabled={readOnly} // Aggiunto
                />
              </div>
              <div className="mb-3">
                <label htmlFor="taskDescription" className="form-label">
                  Descrizione
                </label>
                <textarea
                  className="form-control"
                  id="taskDescription"
                  rows="3"
                  value={taskDescription}
                  onChange={(e) => setTaskDescription(e.target.value)}
                ></textarea>
              </div>
              <div className="mb-3">
                <label htmlFor="taskDueDate" className="form-label">
                  Data di Scadenza
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="taskDueDate"
                  value={taskDueDate}
                  onChange={(e) => setTaskDueDate(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="taskPriority" className="form-label">
                  Priorità
                </label>
                <select
                  id="taskPriority"
                  className="form-select"
                  value={taskPriority}
                  onChange={(e) => setTaskPriority(parseInt(e.target.value))}
                >
                  <option value={1}>1 - Molto Alta</option>
                  <option value={2}>2 - Alta</option>
                  <option value={3}>3 - Media</option>
                  <option value={4}>4 - Bassa</option>
                  <option value={5}>5 - Molto Bassa</option>
                </select>
              </div>
              {taskToEdit && (
                <div className="mb-3">
                  <label htmlFor="taskStatus" className="form-label">
                    Stato
                  </label>
                  <select
                    id="taskStatus"
                    className="form-select"
                    value={taskStatus}
                    onChange={(e) => setTaskStatus(e.target.value)}
                    disabled={readOnly} // Campo stato non modificabile
                  >
                    <option value="todo">Da Fare</option>
                    <option value="in-progress">In Corso</option>
                    <option value="done">Completato</option>
                  </select>
                </div>
              )}
            </div>
            <div className="modal-footer">
              {taskToEdit &&
                !readOnly && ( // Mostra solo se non è in modalità sola lettura
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={handleDelete}
                  >
                    Elimina
                  </button>
                )}
              <button
                type="button"
                className="btn btn-secondary"
                onClick={onClose}
              >
                Chiudi
              </button>
              {!readOnly && ( // Nascondi il pulsante "Salva Modifiche" in modalità sola lettura
                <button type="submit" className="btn btn-primary">
                  {taskToEdit ? "Salva Modifiche" : "Aggiungi"}
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default TaskModalWeb;
