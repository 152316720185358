import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const PasswordPrompt = ({ onPasswordCorrect }) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  useEffect(() => {
    // Controlla se l'utente è già autenticato
    const storedPassword = localStorage.getItem('rememberedPassword');
    if (storedPassword === process.env.REACT_APP_PASSWORD) {
      onPasswordCorrect();
    }
  }, [onPasswordCorrect]);

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (password === process.env.REACT_APP_PASSWORD) {
      if (rememberMe) {
        // Salva la password o un token nel localStorage
        localStorage.setItem('rememberedPassword', password);
      }
      onPasswordCorrect();
    } else {
      setError(true);
    }
  };

  return (
    <div className="d-flex align-items-center justify-content-center vh-100 bg-light">
      <div className="card p-4 shadow">
        <div className="text-center mb-3">
          <h4>Bacheca Roydermal</h4>
          <p>Inserisci la password per continuare</p>
        </div>
        <form onSubmit={handlePasswordSubmit}>
          <div className="mb-3">
            <input
              type="password"
              className="form-control"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="form-check mb-3">
            <input
              type="checkbox"
              className="form-check-input"
              id="rememberMe"
              checked={rememberMe}
              onChange={(e) => setRememberMe(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="rememberMe">
              Ricordami
            </label>
          </div>
          {error && <div className="text-danger mb-3">Password errata. Riprova.</div>}
          <button type="submit" className="btn btn-primary w-100 btn-action">
            Accedi
          </button>
        </form>
      </div>
    </div>
  );
};

export default PasswordPrompt;
