import React, { useState, useEffect, useCallback } from 'react';
import TaskModalPubblicitari from './TaskModalPubblicitari';
import TaskModalDispositivi from './TaskModalDispositivi';
import TaskModalWeb from './TaskModalWeb';
import TaskModalMagazzino from './TaskModalMagazzino';
import TaskTable from './TaskTable';
import PrintTypeSelector from './PrintTypeSelector';
import DevicesTypeSelector from './DevicesTypeSelector';
import TagInput from './TagInput';
import { createIcons } from 'duo-icons';
import _ from 'lodash';

function TaskBoard({ category, setArchivedTasks }) {
  const [tasks, setTasks] = useState([]);
  const [taskToEdit, setTaskToEdit] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState('');
  const [selectedPrintType, setSelectedPrintType] = useState('Mostra Tutto');
  const [selectedDevicesType, setSelectedDevicesType] = useState('Mostra Tutto');
  const [selectedTags, setSelectedTags] = useState([]);
  const [sortOrder, setSortOrder] = useState('priority');
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);

  const processTask = (task) => ({
    ...task,
    print: Boolean(task.print),
    print_status: task.print_status || '',
    print_type: task.print_type || 'Mostra Tutto',
    devices_type: task.devices_type || 'Mostra Tutto',
  });

  const fetchTasks = useCallback(async () => {
    try {
      const branchFilter = selectedBranch ? `&branch=${selectedBranch}` : '';
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/tasks?category=${category}&archived=false${branchFilter}`
      );

      if (!response.ok) throw new Error('Errore nel caricamento delle task');
      const data = await response.json();
      const processedTasks = data.map(processTask);

      setTasks(processedTasks);
      setArchivedTasks(processedTasks.filter((task) => task.archived));
    } catch (error) {
      console.error('Errore nel caricamento delle task:', error);
    }
  }, [category, selectedBranch, setArchivedTasks]);

  useEffect(() => {
    try {
      createIcons();
    } catch (error) {
      console.error('Errore durante l\'inizializzazione delle icone:', error);
    }
  }, [category]);

  useEffect(() => {
    fetchTasks();
  }, [fetchTasks]);

  const onStatusChange = (taskId, newStatus) => {
    const updatedTasks = tasks.map((task) =>
      task.id === taskId ? { ...task, status: newStatus } : task
    );
    setTasks(updatedTasks);

    // Aggiorna il server
    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/tasks/${taskId}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ status: newStatus }),
    }).catch((error) => {
      console.error('Errore durante l\'aggiornamento dello stato:', error);
      // Ripristina lo stato precedente in caso di errore
      setTasks(tasks);
    });
  };

  

  const filteredTasks = _.filter(tasks, (task) => {
    if (selectedBranch && task.branch !== selectedBranch) {
      return false;
    }

    if (
      category === 'Pubblicitari' &&
      selectedPrintType !== 'Mostra Tutto' &&
      task.print_type !== selectedPrintType
    ) {
      return false;
    }

    if (
      category === 'Dispositivi' &&
      selectedDevicesType !== 'Mostra Tutto' &&
      task.devices_type !== selectedDevicesType
    ) {
      return false;
    }

    if (
      selectedTags.length > 0 &&
      !selectedTags.every((tag) => task.tags?.includes(tag))
    ) {
      return false;
    }

    return true;
  });

  const sortedTasks = [...filteredTasks].sort((a, b) => {
    if (sortOrder === 'priority') {
      return b.priority - a.priority;
    } else if (sortOrder === 'date') {
      return new Date(b.created_at) - new Date(a.created_at);
    }
    return 0;
  });

  const toggleAdvancedFilters = () => {
    setShowAdvancedFilters((prev) => !prev);

    if (showAdvancedFilters) {
      setSelectedPrintType('Mostra Tutto');
      setSelectedDevicesType('Mostra Tutto');
    }
  };

  // Funzione per archiviare una task
const onArchive = (taskId) => {
  fetch(`${process.env.REACT_APP_API_BASE_URL}/api/tasks/${taskId}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ archived: true }),
  })
    .then(() => {
      setTasks((prevTasks) => prevTasks.filter((task) => task.id !== taskId));
      console.log(`Task ${taskId} archiviata con successo`);
    })
    .catch((error) => {
      console.error('Errore durante l\'archiviazione della task:', error);
    });
};

const onPrintStatusChange = (taskId, newStatus) => {
  const updatedTasks = tasks.map((task) =>
    task.id === taskId ? { ...task, print_status: newStatus } : task
  );
  setTasks(updatedTasks);

  // Aggiorna il server
  fetch(`${process.env.REACT_APP_API_BASE_URL}/api/tasks/${taskId}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ print_status: newStatus }),
  }).catch((error) => {
    console.error("Errore durante l'aggiornamento dello stato di stampa:", error);
    setTasks(tasks); // Ripristina in caso di errore
  });
};


  const renderTaskModal = () => {
    const modalProps = {
      isOpen: isModalOpen,
      onClose: () => {
        setIsModalOpen(false);
        setTaskToEdit(null);
      },
      taskToEdit,
      onTaskAdded: (newTask) => { // Aggiungi la funzione onTaskAdded
        setTasks((prevTasks) => [...prevTasks, newTask]);
      },
      onTaskUpdated: (updatedTask) => { // Aggiungi questa funzione
        setTasks((prevTasks) =>
          prevTasks.map((task) =>
            task.id === updatedTask.id ? updatedTask : task
          )
        );
      },
      onTaskDeleted: (deletedTaskId) => { 
        setTasks((prevTasks) => prevTasks.filter((task) => task.id !== deletedTaskId));
      },
    };
  
    switch (category) {
      case 'Pubblicitari':
        return <TaskModalPubblicitari {...modalProps} />;
      case 'Dispositivi':
        return <TaskModalDispositivi {...modalProps} />;
      case 'Web':
        return <TaskModalWeb {...modalProps} />;
      case 'Magazzino':
        return <TaskModalMagazzino {...modalProps} />;
      default:
        return null;
    }
  };
  
  

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h4>{category}</h4>
        <button
          className="btn-action btn btn-primary"
          onClick={() => setIsModalOpen(true)}
        >
          <i data-duoicon="add-circle"></i>
          <span style={{ paddingLeft: '10px' }}>Aggiungi Attività</span>
        </button>
      </div>

      {/* Filtri */}
      <div className="mb-3">
        <label htmlFor="branchSelector" className="form-label">Filtra per Branch</label>
        <select
          id="branchSelector"
          className="form-select"
          value={selectedBranch}
          onChange={(e) => setSelectedBranch(e.target.value)}
        >
          <option value="">Mostra Tutto</option>
          <option value="Roydermal">Roydermal</option>
          <option value="Roypet">Roypet</option>
          <option value="Hevalight">Hevalight</option>
          <option value="MgGroup">MgGroup</option>
          <option value="Condiviso">Condiviso</option>
          
        </select>
      </div>

      <button
        className="btn btn-secondary mb-3"
        onClick={toggleAdvancedFilters}
      >
        {showAdvancedFilters ? 'Nascondi Filtri Avanzati' : 'Mostra Filtri Avanzati'}
      </button>

      {showAdvancedFilters && (
        <>
          {category === 'Pubblicitari' && (
            <PrintTypeSelector
              value={selectedPrintType}
              onChange={(value) => setSelectedPrintType(value)}
            />
          )}

          {category === 'Dispositivi' && (
            <DevicesTypeSelector
              value={selectedDevicesType}
              onChange={(value) => setSelectedDevicesType(value)}
            />
          )}

          <TagInput
            selectedTags={selectedTags}
            onTagsChange={setSelectedTags}
          />
        </>
      )}

      <div className="mb-3">
        <label htmlFor="sortOrder" className="form-label">Ordina per</label>
        <select
          id="sortOrder"
          className="form-select"
          value={sortOrder}
          onChange={(e) => setSortOrder(e.target.value)}
        >
          <option value="priority">Priorità</option>
          <option value="date">Data</option>
        </select>
      </div>

      {['todo', 'in-progress', 'in-print', 'done']
        .filter((status) => !((category === 'Web' || category === 'Magazzino') && status === 'in-print'))
        .map((status) => (
          <TaskTable
            key={status}
            title={statusMap[status]}
            tasks={sortedTasks.filter((task) => task.status === status)}
            onEdit={(task) => {
              setTaskToEdit(task);
              setIsModalOpen(true);
            }}
            onStatusChange={onStatusChange}
            onArchive={onArchive} // Passa la funzione onArchive
            activeCategory={category}
            onPrintStatusChange={onPrintStatusChange} // Passa la funzione corretta
          />
        ))}

      {renderTaskModal()}
    </div>
  );
}

const statusMap = {
  todo: 'Da Fare',
  'in-progress': 'In Corso',
  'in-print': 'In Stampa',
  done: 'Completato',
};

export default TaskBoard;