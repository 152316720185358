import React, { useState, useEffect } from "react";
import BranchSelector from "./BranchSelector";
import DevicesTypeSelector from "./DevicesTypeSelector";
import TagInput from "./TagInput"; // Importa il componente TagInput
import { format } from "date-fns";
import { it } from "date-fns/locale";
import ProductModal from "./ProductModal"; // Usa lo stesso componente ProductModal in sola lettura
import "../assets/css/ArchivedTask.css";

function ArchivedProducts({ archivedProducts }) {
  const [branchFilter, setBranchFilter] = useState("");
  const [devicesTypeFilter, setDevicesTypeFilter] = useState("Mostra Tutto");
  const [selectedTags, setSelectedTags] = useState([]); // Stato per i tag selezionati
  const [sortOrder, setSortOrder] = useState("desc");

  // Stato per la modale
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null); // Prodotto selezionato

  // Fetch archived products if not passed as props
  const [products, setProducts] = useState(archivedProducts || []);

  useEffect(() => {
    if (!archivedProducts) {
      fetch(`${process.env.REACT_APP_API_BASE_URL}/api/products?archived=true`)
        .then((response) => response.json())
        .then((data) => setProducts(data))
        .catch((error) =>
          console.error("Error fetching archived products:", error)
        );
    }
  }, [archivedProducts]);

  // Filter products
  const filteredProducts = products.filter((product) => {
    // Apply branch filter if needed
    if (branchFilter && product.branch !== branchFilter) return false;
    // Apply devices type filter if needed
    if (
      devicesTypeFilter !== "Mostra Tutto" &&
      product.devices_type !== devicesTypeFilter
    )
      return false;
    // Apply tag filter if needed
    if (
      selectedTags.length > 0 &&
      !selectedTags.every((tag) => product.tags?.includes(tag))
    )
      return false;
    return true;
  });

  // Sort products by completion date
  const sortedProducts = [...filteredProducts].sort((a, b) => {
    const dateA = a.completed_at ? new Date(a.completed_at) : new Date(0);
    const dateB = b.completed_at ? new Date(b.completed_at) : new Date(0);
    return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
  });

  const toggleSortOrder = () => {
    setSortOrder((prev) => (prev === "asc" ? "desc" : "asc"));
  };

  const resetFilters = () => {
    setBranchFilter("");
    setDevicesTypeFilter("Mostra Tutto");
    setSelectedTags([]);
  };

  const openModal = (product) => {
    setSelectedProduct(product);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedProduct(null);
    setIsModalOpen(false);
  };

  return (
    <div className="mb-4">
      <h3>Archivio Prodotti</h3>

      {/* Filters */}
      <div className="filters mb-4">
        <div className="row">
          <div className="col-md-3">
            <BranchSelector value={branchFilter} onChange={setBranchFilter} />
          </div>
          <div className="col-md-3">
            <DevicesTypeSelector
              value={devicesTypeFilter}
              onChange={setDevicesTypeFilter}
            />
          </div>
          <div className="col-md-8">
            <TagInput
              selectedTags={selectedTags}
              onTagsChange={setSelectedTags}
            />
          </div>
        </div>

        <div className="mt-3">
          <button className="btn btn-secondary me-2" onClick={resetFilters}>
            Resetta Filtri
          </button>
          <button className="btn btn-link" onClick={toggleSortOrder}>
            Ordina {sortOrder === "asc" ? "↑" : "↓"}
          </button>
        </div>
      </div>

      {/* Products Table */}
      {sortedProducts.length > 0 ? (
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Nome</th>
              <th>Branch</th>
              <th>Tipo Dispositivo</th>
              <th>Cambio INCI</th>
              <th>Cambio Grafica</th>
              <th>Data di Completamento</th>
              <th>Tag</th>
              <th>Azioni</th>
            </tr>
          </thead>
          <tbody>
            {sortedProducts.map((product) => (
              <tr key={product.id}>
                <td>{product.name}</td>
                <td>{product.branch || "N/D"}</td>
                <td>{product.devices_type || "N/D"}</td>
                <td>
                  {product.inci_change ? (
                    <span>&#10004;</span>
                  ) : (
                    <span>&#10008;</span>
                  )}
                </td>
                <td>
                  {product.graphic_change ? (
                    <span>&#10004;</span>
                  ) : (
                    <span>&#10008;</span>
                  )}
                </td>
                <td>
                  {product.completed_at
                    ? format(new Date(product.completed_at), "dd MMMM yyyy", {
                        locale: it,
                      })
                    : "N/D"}
                </td>
                <td>{product.tags?.join(", ") || "Nessun tag"}</td>
                <td>
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() => openModal(product)}
                  >
                    Visualizza
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p className="text-center">
          Nessun prodotto trovato con i filtri attuali.
        </p>
      )}

      {/* Modale per il prodotto selezionato */}
      {isModalOpen && selectedProduct && (
        <ProductModal
          isOpen={isModalOpen}
          onClose={closeModal}
          onSave={() => {}}
          productData={selectedProduct}
          tasksInPrint={[]}
          readOnly={true} // Modal in sola lettura
        />
      )}
    </div>
  );
}

export default ArchivedProducts;
