import React, { useState, useEffect } from "react";
// In TagInput.js
import "../assets/css/TagInput.css";






function TagInput({ selectedTags, onTagsChange, readOnly = false }) {
    const [availableTags, setAvailableTags] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [suggestions, setSuggestions] = useState([]);
  
    useEffect(() => {
      const fetchTags = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/tags`);
          if (!response.ok) {
            throw new Error("Errore nella richiesta dei tag");
          }
          const tags = await response.json();
          setAvailableTags(tags.map((tag) => tag.name));
        } catch (error) {
          console.error("Errore durante il recupero dei tag:", error);
        }
      };
  
      fetchTags();
    }, []);
  
    const handleInputChange = (e) => {
      if (readOnly) return; // Non fare nulla se in modalità sola lettura
      const value = e.target.value;
      setInputValue(value);
  
      if (value.trim() !== "") {
        const matches = availableTags.filter((tag) =>
          tag.toLowerCase().includes(value.toLowerCase())
        );
        setSuggestions(matches);
      } else {
        setSuggestions([]);
      }
    };
  
    const handleAddTag = (tag) => {
      if (readOnly) return; // Non aggiungere tag se in modalità sola lettura
      if (!selectedTags.includes(tag)) {
        const updatedTags = [...selectedTags, tag];
        onTagsChange(updatedTags);
      }
      setInputValue("");
      setSuggestions([]);
    };
  
    const handleRemoveTag = (tag) => {
      if (readOnly) return; // Non rimuovere tag se in modalità sola lettura
      onTagsChange(selectedTags.filter((t) => t !== tag));
    };
  
    return (
      <div className="tag-input mb-3">
        <label className="form-label">Tag</label>
        <div className={`tags-container form-control ${readOnly ? 'read-only' : ''}`}>
          {selectedTags.map((tag, index) => (
            <span key={index} className="tag badge bg-primary">
              {tag}
              {!readOnly && ( // Nascondi il pulsante di rimozione se in modalità sola lettura
                <button
                  type="button"
                  className="btn-close btn-close-white ms-2"
                  onClick={() => handleRemoveTag(tag)}
                  aria-label="Remove"
                ></button>
              )}
            </span>
          ))}
          {!readOnly && ( // Nascondi l'input se in modalità sola lettura
            <input
              type="text"
              className="input-field"
              value={inputValue}
              onChange={handleInputChange}
              placeholder="Aggiungi un tag"
            />
          )}
        </div>
        {suggestions.length > 0 && !readOnly && (
          <ul className="list-group mt-2">
            {suggestions.map((tag, index) => (
              <li
                key={index}
                className="list-group-item list-group-item-action"
                onClick={() => handleAddTag(tag)}
              >
                {tag}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
  
  export default TagInput;
  
